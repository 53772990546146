<template>
  <v-container>
    <v-row
      justify="left"
    >
      <v-col
        cols="12"
        class="px-0"
      >
        <base-material-card
          color="info"
        >
          <template v-slot:heading>
            <div class="text-h1 font-weight-light">
              Curriculum Vitae
            </div>

            <div class="text-h5 font-weight-light">
              To download the full PDF, click
              <a
                href="/Trinh, Wei.pdf"
                target="_blank"
              >
                <font
                  size="3.5"
                  class="bold white--text"
                >
                  here.
                </font>
              </a>
            </div>
          </template>
          <v-timeline
            dense
          >
            <!-- Education Tab -->
            <v-timeline-item
              color="info"
              icon="mdi-school"
              fill-dot
              large
              class="text-left"
            >
              <base-material-card
                title="Education"
                height="100%"
                color="#454545"
                outlined
              >
                <!-- Fills in the information below the header. -->
                <v-card-text
                  class="text-left"
                >
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    Texas A&M University
                  </font>
                  <font
                    size="4"
                  >
                    | Ph.D. in Electrical Engineering | August 2016 - Present | College Station, TX
                  </font>
                  <br>
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    University of Maryland, Baltimore County
                  </font>
                  <font
                    size="4"
                  >
                    | B.S. in Physics | August 2012 - June 2016 | Baltimore, MD
                  </font>
                  <br>
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    University of Maryland, Baltimore County
                  </font>
                  <font
                    size="4"
                  >
                    | B.S. in Mathematics | August 2012 - June 2016 | Baltimore, MD
                  </font>
                </v-card-text>
              </base-material-card>
            </v-timeline-item>
            <!-- Experience Tab -->
            <v-timeline-item
              color="info"
              icon="mdi-account-details"
              fill-dot
              large
              class="text-left"
            >
              <base-material-card
                title="Experience"
                height="100%"
                color="#454545"
                outlined
              >
                <!-- Fills in the information below the header. -->
                <!-- NAPS Section -->
                <v-card-text
                  class="text-left"
                >
                  <v-card
                    elevation="5"
                    outlined
                  >
                    <v-card-title
                      class="font-weight-bold"
                    >
                      53rd North American Power Symposium (NAPS) | College Station, TX
                    </v-card-title>
                    <!-- ECEN 459 Position -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Industry Outreach | June 2021 - December 2020
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Worked with planning committee on industry outreach for NAPS 2021.  <br>
                            • Established contacts with industry partners for NAPS 2021.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                  <!-- TPEC Section -->
                  <v-card
                    elevation="5"
                    outlined
                  >
                    <v-card-title
                      class="font-weight-bold"
                    >
                      IEEE Texas Power and Energy Conference | College Station, TX
                    </v-card-title>
                    <!-- TPEC 2021 -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            TPEC 2021 | Promotion Logistics Chair | March 2020 - February 2021
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Working on social media for the event, for promotional and outreach purposes. <br>
                            • Developed magazine for the conference, to be distributed to the attendees. <br>
                            • Worked on maintaining and modernizing website for publicity.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- TPEC 2020 -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            TPEC 2020 | Co-Director | March 2019 - February 2020
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Directed conference planning in all avenues including financial, logistical, and technical processes. <br>
                            • Established and recruited the planning committee, along with building a planning framework that would give
                            more details for future generations to base their planning off of. <br>
                            • Helped to establish the planning committee for the next TPEC.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- TPEC 2019 -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            TPEC 2019 | Committee Member and Reviewer | August 2018 - February 2019
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Helped to contact and arrange financial sponsors for the event. <br>
                            • Acted as a liaison between financial sponsors and the committee during the conference. <br>
                            • Reviewed submissions for the conference.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                  <!-- TAMU Section -->
                  <v-card
                    elevation="5"
                    outlined
                  >
                    <v-card-title
                      class="font-weight-bold"
                    >
                      TAMU Department of Electrical Engineering | College Station, TX
                    </v-card-title>
                    <!-- ECEN 459 Position -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Teaching Assistant for ECEN 459 | August 2020 - December 2020
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Instructed students on the fundamentals of 3ϕ power systems and fault analysis. <br>
                            • Developed lab manuals and activities for students to work on remotely during COVID-19 pandemic.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- ECEN 460 Position -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Teaching Assistant for ECEN 460 | August 2018 - May 2019
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Instructed students on the fundamentals of 3ϕ power systems, and provided guidance in learning how to use
                            commercialized power systems analysis packages, like PowerWorld. <br>
                            • Developed a Power Market analysis tool with Zeyu Mao to enable students to participate in simplified power markets
                            to gain experience.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- ECEN 215 Position -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Teaching Assistant for ECEN 215 | August 2016 - May 2018
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Instructed students on basic circuit design and developed students abilities to analyze and understand circuits. <br>
                            • Revamped the entire lab manual for the course, making it more intuitive for students unfamiliar with circuit theory, and
                            included ample reference material for students to use to understand how to build and understand circuits.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                  <!-- UMBC Physics Section -->
                  <v-card
                    elevation="5"
                    outlined
                  >
                    <v-card-title
                      class="font-weight-bold"
                    >
                      UMBC Department of Physics | Baltimore, MD
                    </v-card-title>
                    <!-- LA Position -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Learning Assistant for PHYS 121 | January 2013 - May 2014
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Assisted students with learning basic Physics principles, and the visualization of said principles through the use of Python.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- TPEC 2021 -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Lab Setup Assistant | June 2014 - June 2016
                          </font>
                          <br>
                          <font
                            size="4"
                          >
                            • Helped to set up and check all laboratory equipment for the introductory labs. <br>
                            • Optimized and assisted in the restructuring of student labs for better delivery of Physics concepts.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                  <!-- UMBC Meyerhoff Section -->
                  <v-card
                    elevation="5"
                    outlined
                  >
                    <v-card-title
                      class="font-weight-bold"
                    >
                      UMBC Meyerhoff Scholars Program | Baltimore, MD
                    </v-card-title>
                    <!-- Council Member -->
                    <v-col
                      cols="12"
                    >
                      <v-card
                        elevation="5"
                        width="99.5%"
                      >
                        <v-card-text>
                          <font
                            size="4"
                            class="font-weight-bold"
                          >
                            Meyerhoff Council Member | August 2012 - June 2016 <br>
                          </font>
                          <font
                            size="4"
                          >
                            • Acted as a liaison between students and faculty of the Meyerhoff Program. <br>
                            • Organized and ran various student events in order to cultivate a family environment in the program.
                          </font>
                          <br>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                </v-card-text>
              </base-material-card>
            </v-timeline-item>
            <!-- Research Tab -->
            <v-timeline-item
              color="info"
              icon="mdi-magnify-scan"
              fill-dot
              large
            >
              <base-material-card
                title="Research"
                height="100%"
                color="#454545"
                outlined
              >
                <v-card
                  elevation="5"
                  outlined
                >
                  <v-card-title
                    class="font-weight-bold"
                  >
                    Overbye Research Group | Graduate Research Assistant
                  </v-card-title>
                  <!-- Fills in the information below the header. -->
                  <v-card-text
                    class="text-left"
                  >
                    <font
                      size="4"
                    >
                      PI: Dr. Thomas Overbye | February 2018 – Present | College Station, TX
                    </font>
                    <br>
                    <v-divider />
                    <br>
                    <font
                      size="3.5"
                    >
                      Currently researching modal analysis techniques for large-scale synthetic power systems, and how they can be leveraged to
                      analyze and understand the nature of modes in an electric grid.
                    </font>
                  </v-card-text>
                </v-card>
                <v-card
                  elevation="5"
                  outlined
                >
                  <v-card-title
                    class="font-weight-bold"
                  >
                    Chen Lab | REU Research Assistant
                  </v-card-title>
                  <!-- Fills in the information below the header. -->
                  <v-card-text
                    class="text-left"
                  >
                    <font
                      size="4"
                    >
                      PI: Dr. Long-Qing Chen | May 2015 - August 2015 | State College, PA
                    </font>
                    <br>
                    <v-divider />
                    <br>
                    <font
                      size="3.5"
                    >
                      Comsol was used to optimize a computational model of a thin-film polymer capacitor, with the aim of minimizing the internal
                      temperature based on physical constraints.
                    </font>
                  </v-card-text>
                </v-card>
                <v-card
                  elevation="5"
                  outlined
                >
                  <v-card-title
                    class="font-weight-bold"
                  >
                    Sparling Lab | Undergraduate Research Assistant
                  </v-card-title>
                  <!-- Fills in the information below the header. -->
                  <v-card-text
                    class="text-left"
                  >
                    <font
                      size="4"
                    >
                      PI: Dr. Lynn Sparling | December 2015 - August 2016 | Baltimore, MD
                    </font>
                    <br>
                    <v-divider />
                    <br>
                    <font
                      size="3.5"
                    >
                      Used a combination of Python and Mathematica to analyze large sets of wind data, in order to determine the most optimal design
                      for a wind farm over a given piece of land.
                    </font>
                  </v-card-text>
                </v-card>
              </base-material-card>
            </v-timeline-item>
            <!-- Publications -->
            <v-timeline-item
              color="info"
              icon="mdi-file-document-multiple"
              fill-dot
              large
            >
              <base-material-card
                title="Publications"
                height="100%"
                color="#454545"
                outlined
              >
                <!-- Fills in the information below the header. -->
                <v-card-text
                  class="text-left"
                >
                  <!-- Paper 1 -->
                  <font
                    size="3.5"
                  >
                    W. Trinh and T.J. Overbye, "Sensitivity of Modes from Modal Analysis of Electric Grids,"
                    <font
                      class="font-italic"
                    >
                      Kansas Power and Energy Conference (KPEC),
                    </font>
                    April 2021
                  </font>
                  <br> <br>
                  <!-- Paper 2 -->
                  <font
                    size="3.5"
                  >
                    W. Trinh, Z. Mao, T. J. Overbye, J. D. Weber, and D. J. Morrow,
                    "Considerations in the Initialization of Power Flow Solutions from Dynamic Simulation Snapshots,"
                    <font
                      class="font-italic"
                    >
                      2020 North American Power Symposium (NAPS 2020),
                    </font>
                    April 2021
                  </font>
                  <br> <br>
                  <!-- Paper 3 -->
                  <font
                    size="3.5"
                  >
                    J. Yeo, W. Trinh, W. Jang, and T. Overbye,
                    "Assessment of Multirate Method for Power System Dynamics Analysis,"
                    <font
                      class="font-italic"
                    >
                      2020 North American Power Symposium (NAPS 2020),
                    </font>
                    April 2021
                  </font>
                  <br> <br>
                  <!-- Paper 4 -->
                  <font
                    size="3.5"
                  >
                    T.J. Overbye, K.S. Shetye, J.L. Wert, W. Trinh, and A. Birchfield,
                    "Techniques for Maintaining Situational Awareness During Large-Scale Electric Grid Simulations,"
                    <font
                      class="font-italic"
                    >
                      IEEE Power and Energy Conference at Illinois (PECI),
                    </font>
                    April 2021
                  </font>
                  <br> <br>
                  <!-- Paper 5 -->
                  <font
                    size="3.5"
                  >
                    H. Li, W. Trinh, J. Wert, M. Dawkins, T. Overbye, and J. Weber,
                    "Applications of DC Power Flow Based Power System Dynamic Simulations,"
                    <font
                      class="font-italic"
                    >
                      IEEE Texas Power and Energy Conference 2020,
                    </font>
                    February 2020
                  </font>
                  <br> <br>
                  <!-- Paper 7 -->
                  <font
                    size="3.5"
                  >
                    W. Trinh, and T. Overbye,
                    "Comparison of Dynamic Mode Decomposition and Iterative Matrix Pencil Method for Power System Modal Analysis,"
                    <font
                      class="font-italic"
                    >
                      2019 International Conference on Smart Grid Synchronized Measurements and Analytics,
                    </font>
                    May 2019
                  </font>
                  <br> <br>
                  <font
                    size="3.5"
                  >
                    W. Trinh, K.S. Shetye, I. Idehen, and T. Overbye
                    "terative Matrix Pencil Method for Power System Model Analysis,"
                    <font
                      class="font-italic"
                    >
                      2019 52nd Hawaii International Conference on System Sciences
                    </font>
                    January 2019
                  </font>
                  <br> <br>
                  <!-- Paper 8 -->
                  <font
                    size="3.5"
                  >
                    W. Trinh, J. Tyler, S. Villareal, M. Rahimian, and N. Gober, "ECEN 215 Lab Manual,"
                    <font
                      class="font-italic"
                    >
                      ECEN 215 Course,
                    </font>
                    August 2018
                  </font>
                  <br> <br>
                  <!-- Paper 9 -->
                  <font
                    size="3.5"
                  >
                    Z. Wang, Q. Li, W. Trinh, Q. Lu, H. Cho, Q. Wang, and L. Chen,
                    "Optimal design of high temperature metalized thin-film polymer capacitors: A combined numerical and experimental method,"
                    <font
                      class="font-italic"
                    >
                      Journal of Power Sources,
                    </font>
                    vol. 357, pp. 149 – 157, 2017.
                  </font>
                  <br> <br>
                </v-card-text>
              </base-material-card>
            </v-timeline-item>
            <!-- Skills  -->
            <v-timeline-item
              color="info"
              icon="mdi-code-greater-than-or-equal"
              fill-dot
              large
            >
              <base-material-card
                title="Skills"
                height="100%"
                color="#454545"
                outlined
              >
                <!-- Fills in the information below the header. -->
                <v-card-text
                  class="text-left"
                >
                  <font
                    size="3.5"
                  >
                    Python • PowerWorld • Matlab • Mathematica • VueJS • LaTeX
                  </font>
                </v-card-text>
              </base-material-card>
            </v-timeline-item>
            <!-- Honors -->
            <v-timeline-item
              color="info"
              icon="mdi-seal-variant"
              fill-dot
              large
            >
              <base-material-card
                title="Awards"
                height="100%"
                color="#454545"
                outlined
              >
                <!-- Fills in the information below the header. -->
                <v-card-text
                  class="text-left"
                >
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    Thomas W. Powell ’62 and Powell Industries Inc. Fellowship Recipient
                  </font>
                  <font
                    size="4"
                  >
                    | Texas A&M University | May 2020
                  </font>
                  <br>
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    Thomas W. Powell ’62 and Powell Industries Inc. Fellowship Recipient
                  </font>
                  <font
                    size="4"
                  >
                    | Texas A&M University | May 2019
                  </font>
                  <br>
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    NIBIB Scholar
                  </font>
                  <font
                    size="4"
                  >
                    | University of Maryland, Baltimore County | August 2014
                  </font>
                  <br>
                  <font
                    size="4"
                    class="font-weight-bold"
                  >
                    Meyerhoff Scholar
                  </font>
                  <font
                    size="4"
                  >
                    | University of Maryland, Baltimore County | August 2012
                  </font>
                </v-card-text>
              </base-material-card>
            </v-timeline-item>
          </v-timeline>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardPagesTimeline',
  }
</script>
